import React from "react"
import { Link } from "gatsby"
import styles from "./footer.module.css"

import Container from './container'

export default () => (
  <footer className={styles.footer}>
    <Container>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div>
          <Link to="/sobre/">Sobre</Link>
          <Link to="/contato/">Contato</Link>
        </div>
        <div>
          <a href="https://www.facebook.com/grupovinte/" target="_blank" title="Facebook" rel="noopener noreferrer">
            <img style={{ marginBottom: 0 }} src="/img/facebook.svg" alt="Facebook" />
          </a>
          <a href="https://www.instagram.com/grupovinte/" target="_blank" title="Instagram" rel="noopener noreferrer">
            <img style={{ marginBottom: 0 }} src="/img/instagram.svg" alt="Instagram" />
          </a>
        </div>
      </div>
    </Container>
  </footer>
)
