import React from "react"
import { Link } from "gatsby"
import styles from "./header.module.css"
import { Helmet } from 'react-helmet'

export default () => (
  <header className={styles.header}>
    <Helmet>
      <link href="https://fonts.googleapis.com/css?family=Oswald|Raleway" rel="stylesheet" />
      <meta name="google-site-verification" content="9eGVGnvPyQe4Ch8phn6Vvr4x4sV2HbRYyoKfd5-QaOk" />
    </Helmet>
    <Link to="/">
      <img className={styles.logo} src="/img/logo.png" alt="Logo Grupo Vinte" />
    </Link>
  </header>
)
